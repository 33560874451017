var render = function () {
  var _vm$data, _vm$data2, _vm$data2$datasets$, _vm$data2$datasets$$d;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticStyle: {
      "margin-bottom": "10px !important"
    }
  }, [_c('b-card-header', [_c('label', {
    staticClass: "custom-font"
  }, [_c('b', [_vm._v("Project Status Summary")])])]), (_vm$data = _vm.data) !== null && _vm$data !== void 0 && _vm$data.datasets ? [((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : (_vm$data2$datasets$ = _vm$data2.datasets[0]) === null || _vm$data2$datasets$ === void 0 ? void 0 : (_vm$data2$datasets$$d = _vm$data2$datasets$.data) === null || _vm$data2$datasets$$d === void 0 ? void 0 : _vm$data2$datasets$$d.length) > 0 ? [_c('chartjs-component-doughnut-chart', {
    staticClass: "mb-3",
    attrs: {
      "height": 275,
      "data": _vm.data,
      "options": _vm.options
    }
  })] : [_c('b', {
    staticClass: "d-flex justify-content-center mb-1"
  }, [_vm._v("No Data Found")])]] : [_c('span', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary"
    }
  })], 1)], _c('div', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "font-weight-bold ml-75 mr-25"
  }, [_vm._v("Total")])]), _c('div', {
    staticClass: "mt-1"
  }, [_c('b-badge', {
    style: {
      backgroundColor: _vm.chartColors.primaryColorShade
    }
  }, [_vm._v(" " + _vm._s(_vm.projectStatusCount.total_projects) + " ")])], 1)]), _c('div', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "font-weight-bold ml-75 mr-25"
  }, [_vm._v("Approved")])]), _c('div', {
    staticClass: "mt-1"
  }, [_c('b-badge', {
    style: {
      backgroundColor: _vm.chartColors.blueLightColor
    }
  }, [_vm._v(" " + _vm._s(_vm.projectStatusCount.all_approved_projects) + " ")])], 1)]), _c('div', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "font-weight-bold ml-75 mr-25"
  }, [_vm._v("Pending")])]), _c('div', {
    staticClass: "mt-1"
  }, [_c('b-badge', {
    style: {
      backgroundColor: _vm.chartColors.warningColorShade
    }
  }, [_vm._v(" " + _vm._s(_vm.projectStatusCount.all_pending_projects) + " ")])], 1)]), _c('div', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "font-weight-bold ml-75 mr-25"
  }, [_vm._v("Not Started")])]), _c('div', {
    staticClass: "mt-1"
  }, [_c('b-badge', {
    style: {
      backgroundColor: _vm.chartColors.warningLightColor
    }
  }, [_vm._v(" " + _vm._s(_vm.projectStatusCount.not_started_projects) + " ")])], 1)]), _c('div', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "font-weight-bold ml-75 mr-25"
  }, [_vm._v("Ongoing")])]), _c('div', {
    staticClass: "mt-1"
  }, [_c('b-badge', {
    style: {
      backgroundColor: _vm.chartColors.infoColorShade
    }
  }, [_vm._v(" " + _vm._s(_vm.projectStatusCount.ongoing_projects) + " ")])], 1)]), _c('div', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "font-weight-bold ml-75 mr-25"
  }, [_vm._v("Finished")])]), _c('div', {
    staticClass: "mt-1"
  }, [_c('b-badge', {
    style: {
      backgroundColor: _vm.chartColors.successColorShade
    }
  }, [_vm._v(" " + _vm._s(_vm.projectStatusCount.finished_projects) + " ")])], 1)])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }