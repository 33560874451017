<template>
  <b-card class="card-developer-meetup">
    <b-card-title>Upcoming Holidays</b-card-title>
    <template v-if="items.length > 0">
      <div v-for="(item, index) in items" :key="index" class="">

        <b-row class="mb-2">
          <b-col  cols="2">
            <h4 class="mb-0 text-center">
              {{ formatDate(item.date) }}
            </h4>
          </b-col>
          <b-col  cols="8"><div class="my-auto">
            <b-card-title class="mb-25">
              {{ item.title }}
            </b-card-title>
            <b-card-text class="mb-0">
              {{ item.description }}
            </b-card-text>
          </div></b-col>
        </b-row>



    </div>
    </template>
    <template v-else>
      <b class="d-flex justify-content-center">No Upcoming Holiday</b>
    </template>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BCol,
  BRow,
  BFormDatepicker,
  BFormTextarea,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BTable,
  BCardTitle
} from "bootstrap-vue";

import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { EventBus } from "@/helpers/event-bus";
import { minutesToHours } from "@/helpers/helpers";

export default {
  name: "UpcomingHolidayWidget",
  components: {
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BCol,
    BRow,
    BFormDatepicker,
    BFormTextarea,
    BLink,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    BTable,
    BCardTitle
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // filter
      items: [
        {
          date: "",
          title: "No Data Found",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },
  methods: {
    async getFormatDay(date) {
      const foramtDate = await this.formatDate(date)
    },
    getFormatDate(date){},
    formatDate(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
      return "Not Set";
    },

    async getHolidaysData() {
      return await this.$api.get("api/dashboard/upcoming-holidays");
    },

    async loadItems() {
      try {
        const holiday = await this.getHolidaysData();

        this.items = holiday?.data?.data;

        // if (holidayData?.data?.data.length > 0) {
        //   this.items = holidayData?.data?.data.map((item) => {
        //     return {
        //       date: this.formatDate(item?.date),
        //       title: item?.title,
        //     };
        //   });
        // }

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  },
};
</script>

    <style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.table-custom-style-att {
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: middle !important;
    text-align: center !important;
    font-size: 12px !important;
  }
}
.custom-font {
  font-size: 16px !important;
}
</style>
