var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-row', {
    staticClass: "ml-1"
  }, [_c('b-card-title', [_vm._v("Employee Agreement Renewals")]), _c('div', {
    staticClass: "button-style"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.goEmployees
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ClipboardIcon"
    }
  })], 1)], 1)], 1), _c('b-table', {
    attrs: {
      "responsive": "sm",
      "items": _vm.items,
      "fields": _vm.fields,
      "sticky-header": "",
      "height": "300px"
    },
    scopedSlots: _vm._u([{
      key: "cell(name)",
      fn: function fn(data) {
        var _data$item2;
        return [_c('span', {
          staticClass: "font-weight-bold cursor-pointer text-primary",
          on: {
            "click": function click($event) {
              var _data$item, _data$item$data;
              return _vm.moveToDetails((_data$item = data.item) === null || _data$item === void 0 ? void 0 : (_data$item$data = _data$item.data) === null || _data$item$data === void 0 ? void 0 : _data$item$data.id);
            }
          }
        }, [_vm._v(_vm._s((_data$item2 = data.item) === null || _data$item2 === void 0 ? void 0 : _data$item2.name))])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }