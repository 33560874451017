<template>
  <b-card>
    <b-row>
        <label class="custom-font"><b>Income & Expense Summary (BDT)</b></label>
      <div class="button-style">
        <b-button
          class="flex-shrink-0 mr-1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="goIncomeAndExpense"
        >
        <feather-icon icon="ClipboardIcon" />

        </b-button>
      </div>
    </b-row>



    <b-row>
      <b-col cols="8">
        <template v-if="labels.length && expanseData.length && incomeData.length">
          <app-echart-stacked-area style="width: 100%" :option-data="option" />
        </template>
      </b-col>
      <b-col cols="4" class="d-flex justify-content-center align-items-center">
        <div>

          <div class="mb-1 d-flex justify-content-center align-items-center">
            <b-dropdown
                :text="selectedYear"
                size="sm"
                class="budget-dropdown"
                variant="outline-primary"
            >
              <b-dropdown-item
                  v-for="year in years"
                  :key="year"
                  @click="selectYear(year)"
              >
              {{ year }}
              </b-dropdown-item>
            </b-dropdown>

          </div>

          <h2 class="text-success font-weight-bolder">
            <feather-icon
              icon="ArrowUpIcon"
              size="16"
          /> {{ totalIncome }} BDT
          </h2>
          <h2 class="text-danger font-weight-bolder">
            <feather-icon
                icon="ArrowDownIcon"
                size="16"
            />
            {{ totalExpense }} BDT
          </h2>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

  <script>
  import {BCard, BButton, BRow, BCol, BDropdownItem, BDropdown} from "bootstrap-vue";
import AppEchartStackedArea from "@core/components/charts/echart/AppEchartStackedArea.vue";
import { ex } from "@fullcalendar/core/internal-common";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { heightFade } from "@core/directives/animations";

export default {
  name: "FinanceIncomeAndExpenseWidget",

  components: {
    BCol,
    BCard,
    BButton,
    BRow,
    AppEchartStackedArea,
    BDropdown,
    BDropdownItem,
  },

  directives: {
    Ripple,
    heightFade,
  },

  data() {
    return {
      labels: [],
      expanseData: [],
      incomeData: [],
      selectedYear: new Date().getFullYear().toString(),
      years: this.generateYears(2015),  // Example list of years
      totalIncome: '0',
      totalExpense: '0',
    };
  },

  computed: {
    option() {
      return {
        xAxisData: this.labels,
        yAxisData: this.incomeData,
        series: [
          {
            name: "Expanse",
            type: "line",
            stack: "Total",
            areaStyle: {
              color: "#E97451",
            },
            color: "#E97451",
            showSymbol: false,
            smooth: 0.4,
            lineStyle: {
              width: 0,
            },
            data: this.expanseData,
          },
          {
            name: "Income",
            type: "line",
            stack: "Total",
            areaStyle: {
              color: "#32cd32",
            },
            color: "#32cd32",
            showSymbol: false,
            smooth: 0.4,
            lineStyle: {
              width: 0,
            },
            data: this.incomeData,
          },
        ],
      };
    },
  },

  async created() {
    try {
      await this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    generateYears(startYear) {
      const currentYear = new Date().getFullYear();
      const yearsArray = [];
      for (let year = startYear; year <= currentYear; year++) {
        yearsArray.push(year);
      }
      return yearsArray;
    },
    selectYear(year) {
      this.selectedYear = year.toString();
      this.loadItems()
    },
    goIncomeAndExpense() {
      this.$router.push({
        name: "admin-transaction-histories",
      });
    },

    async getExpanseSummaryCount() {
      return await this.$api.get(`api/dashboard/expense-summary?year=${this.selectedYear}`);
    },

    async getIncomeSummaryCount() {
      return await this.$api.get(`api/dashboard/income-summary?year=${this.selectedYear}`);
    },

    async loadItems() {
      try {
        // Clear the data to trigger re-render
        this.labels = [];
        this.expanseData = [];
        this.incomeData = [];

        // Fetch new data
        const expanseSummaryCount = await this.getExpanseSummaryCount();
        const incomeSummaryCount = await this.getIncomeSummaryCount();

        // Update the chart data with new fetched values
        this.labels = expanseSummaryCount?.data?.data?.date.slice() || [];
        this.expanseData = expanseSummaryCount?.data?.data?.amount.slice() || [];
        this.incomeData = incomeSummaryCount?.data?.data?.amount.slice() || [];
        this.totalExpense = expanseSummaryCount?.data?.data?.total
        this.totalIncome = incomeSummaryCount?.data?.data?.total

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  },
};
</script>
