<template>
  <b-card
      v-if="assetStatus"
      no-body
      class="card-company-table"
  >
    <b-card-body>
      <b-card-title>Assets Summary</b-card-title>
      <b-table
          v-if="assetStatus.length > 0"
          :items="assetStatus"
          responsive
          :fields="fields"
          class="mb-0"
      >
        <!-- company -->
        <template #cell(category)="data">
          <b>{{ data.item.name }}</b>
        </template>

        <template #cell(assets)="data">
          <b-badge variant="primary">
            {{ data.item.total_assets }}
          </b-badge>
        </template>

        <template #cell(available)="data">
          <b-badge variant="info">
            {{ data.item.unassigned_assets_count }}
          </b-badge>
        </template>

        <template #cell(assigned)="data">
          <b-badge variant="success">
            {{ data.item.assigned_assets_count }}
          </b-badge>
        </template>

        <template #cell(damage)="data">
          <b-badge variant="danger">
            {{ data.item.damaged_assets_count }}
          </b-badge>
        </template>

      </b-table>
      <template v-else>
        <div class="text-center m-1 mt-3 font-weight-bold">No Records Found</div>
      </template>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormSelect,
  BPagination,
  BTable,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BFormGroup,
  BInputGroup,
  BLink,
  BButton,

} from "bootstrap-vue";

import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { heightFade } from "@core/directives/animations";

export default {
  name: "AssetSummaryCountWidget",
  components: {
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    StatisticCardVertical,
    BFormSelect,
    BPagination,
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BFormGroup,
    BInputGroup,
    BLink,
    BButton,

  },

  directives: {
    Ripple,
    heightFade,
  },

  data() {
    return {
      fields: [
        { key: 'category', label: 'Category' },
        { key: 'assets', label: 'ASSETS' },
        { key: 'available', label: 'AVAILABLE' },
        { key: 'assigned', label: 'ASSIGNED' },
        { key: 'damage', label: 'DAMAGE' }
      ],
      assetStatus: []
    };
  },

  async created() {
    try {
      const assetStatusCount = await this.getAssetSummaryCount();
      this.assetStatus = assetStatusCount?.data
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    async getAssetSummaryCount() {
      return await this.$api.get(
        "api/dashboard/asset-summary-count"
      );
  },

  goAsset() {
    this.$router.push({
        name: "admin-assets",
      });
  },

},

};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.table-custom-style-att {
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: middle !important;
    text-align: center !important;
    font-size: 12px !important;
  }
}
.custom-font {
  font-size: 13px !important;
}
</style>
