var render = function () {
  var _vm$attendanceStatusS, _vm$attendanceStatusS2, _vm$attendanceStatusS3, _vm$attendanceStatusS4, _vm$attendanceStatusS5, _vm$attendanceStatusS6, _vm$attendanceStatusS7, _vm$attendanceStatusS8, _vm$attendanceStatusS9, _vm$attendanceStatusS10, _vm$attendanceStatusS11, _vm$attendanceStatusS15, _vm$attendanceStatusS16, _vm$attendanceStatusS17, _vm$attendanceStatusS18, _vm$attendanceStatusS19, _vm$attendanceStatusS20, _vm$attendanceStatusS21, _vm$attendanceStatusS22, _vm$attendanceStatusS23, _vm$attendanceStatusS24, _vm$attendanceStatusS25, _vm$attendanceStatusS26, _vm$attendanceStatusS27, _vm$attendanceStatusS28, _vm$attendanceStatusS29, _vm$attendanceStatusS33, _vm$attendanceStatusS34, _vm$attendanceStatusS35, _vm$attendanceStatusS36, _vm$attendanceStatusS37, _vm$attendanceStatusS38, _vm$attendanceStatusS39, _vm$attendanceStatusS40, _vm$attendanceStatusS41, _vm$attendanceStatusS42, _vm$attendanceStatusS43, _vm$attendanceStatusS44, _vm$attendanceStatusS45, _vm$attendanceStatusS46, _vm$attendanceStatusS47, _vm$attendanceStatusS51, _vm$attendanceStatusS52, _vm$attendanceStatusS53, _vm$attendanceStatusS54, _vm$attendanceStatusS55, _vm$attendanceStatusS56, _vm$attendanceStatusS57, _vm$attendanceStatusS58, _vm$attendanceStatusS59, _vm$attendanceStatusS60, _vm$attendanceStatusS61, _vm$attendanceStatusS62, _vm$attendanceStatusS63, _vm$attendanceStatusS64, _vm$attendanceStatusS65, _vm$attendanceStatusS69, _vm$attendanceStatusS70, _vm$attendanceStatusS71, _vm$attendanceStatusS72, _vm$attendanceStatusS73, _vm$attendanceStatusS74, _vm$attendanceStatusS75, _vm$attendanceStatusS76, _vm$attendanceStatusS77, _vm$attendanceStatusS78, _vm$attendanceStatusS79, _vm$attendanceStatusS80, _vm$attendanceStatusS81, _vm$attendanceStatusS82, _vm$attendanceStatusS83, _vm$attendanceStatusS87, _vm$attendanceStatusS88, _vm$attendanceStatusS89, _vm$attendanceStatusS90, _vm$attendanceStatusS91;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-row', [_c('label', {
    staticClass: "custom-font mb-1"
  }, [_c('b', [_vm._v("Attendance Summary : Today")])]), _c('div', {
    staticClass: "button-style"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.goAttendance
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ClipboardIcon"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "mb-1"
  }, [(_vm$attendanceStatusS = _vm.attendanceStatusSummary) !== null && _vm$attendanceStatusS !== void 0 && _vm$attendanceStatusS.present_info ? [_c('div', [_c('b-badge', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mr-1 cursor-pointer",
    class: _vm.filterWith === 'all' ? 'badge-glow' : '',
    attrs: {
      "variant": "primary",
      "title": 'All'
    },
    on: {
      "click": function click($event) {
        return _vm.filterByStatus('all');
      }
    }
  }, [_vm._v(" All ")]), _c('b-badge', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: " mr-1 cursor-pointer",
    class: _vm.filterWith === ((_vm$attendanceStatusS2 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS2 === void 0 ? void 0 : (_vm$attendanceStatusS3 = _vm$attendanceStatusS2.present_info) === null || _vm$attendanceStatusS3 === void 0 ? void 0 : (_vm$attendanceStatusS4 = _vm$attendanceStatusS3.info) === null || _vm$attendanceStatusS4 === void 0 ? void 0 : _vm$attendanceStatusS4.id) ? 'badge-glow' : '',
    style: {
      color: 'white',
      backgroundColor: (_vm$attendanceStatusS5 = (_vm$attendanceStatusS6 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS6 === void 0 ? void 0 : (_vm$attendanceStatusS7 = _vm$attendanceStatusS6.present_info) === null || _vm$attendanceStatusS7 === void 0 ? void 0 : (_vm$attendanceStatusS8 = _vm$attendanceStatusS7.info) === null || _vm$attendanceStatusS8 === void 0 ? void 0 : _vm$attendanceStatusS8.color_code) !== null && _vm$attendanceStatusS5 !== void 0 ? _vm$attendanceStatusS5 : '#7367f0'
    },
    attrs: {
      "title": (_vm$attendanceStatusS9 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS9 === void 0 ? void 0 : (_vm$attendanceStatusS10 = _vm$attendanceStatusS9.present_info) === null || _vm$attendanceStatusS10 === void 0 ? void 0 : (_vm$attendanceStatusS11 = _vm$attendanceStatusS10.info) === null || _vm$attendanceStatusS11 === void 0 ? void 0 : _vm$attendanceStatusS11.name
    },
    on: {
      "click": function click($event) {
        var _vm$attendanceStatusS12, _vm$attendanceStatusS13, _vm$attendanceStatusS14;
        return _vm.filterByStatus((_vm$attendanceStatusS12 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS12 === void 0 ? void 0 : (_vm$attendanceStatusS13 = _vm$attendanceStatusS12.present_info) === null || _vm$attendanceStatusS13 === void 0 ? void 0 : (_vm$attendanceStatusS14 = _vm$attendanceStatusS13.info) === null || _vm$attendanceStatusS14 === void 0 ? void 0 : _vm$attendanceStatusS14.id);
      }
    }
  }, [_vm._v(" " + _vm._s((_vm$attendanceStatusS15 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS15 === void 0 ? void 0 : (_vm$attendanceStatusS16 = _vm$attendanceStatusS15.present_info) === null || _vm$attendanceStatusS16 === void 0 ? void 0 : (_vm$attendanceStatusS17 = _vm$attendanceStatusS16.info) === null || _vm$attendanceStatusS17 === void 0 ? void 0 : _vm$attendanceStatusS17.sort_name) + " " + _vm._s((_vm$attendanceStatusS18 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS18 === void 0 ? void 0 : (_vm$attendanceStatusS19 = _vm$attendanceStatusS18.present_info) === null || _vm$attendanceStatusS19 === void 0 ? void 0 : _vm$attendanceStatusS19.count) + " ")]), _c('b-badge', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mr-1 cursor-pointer",
    class: _vm.filterWith === ((_vm$attendanceStatusS20 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS20 === void 0 ? void 0 : (_vm$attendanceStatusS21 = _vm$attendanceStatusS20.leave_info) === null || _vm$attendanceStatusS21 === void 0 ? void 0 : (_vm$attendanceStatusS22 = _vm$attendanceStatusS21.info) === null || _vm$attendanceStatusS22 === void 0 ? void 0 : _vm$attendanceStatusS22.id) ? 'badge-glow' : '',
    style: {
      color: 'white',
      backgroundColor: (_vm$attendanceStatusS23 = (_vm$attendanceStatusS24 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS24 === void 0 ? void 0 : (_vm$attendanceStatusS25 = _vm$attendanceStatusS24.leave_info) === null || _vm$attendanceStatusS25 === void 0 ? void 0 : (_vm$attendanceStatusS26 = _vm$attendanceStatusS25.info) === null || _vm$attendanceStatusS26 === void 0 ? void 0 : _vm$attendanceStatusS26.color_code) !== null && _vm$attendanceStatusS23 !== void 0 ? _vm$attendanceStatusS23 : '#7367f0'
    },
    attrs: {
      "title": (_vm$attendanceStatusS27 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS27 === void 0 ? void 0 : (_vm$attendanceStatusS28 = _vm$attendanceStatusS27.leave_info) === null || _vm$attendanceStatusS28 === void 0 ? void 0 : (_vm$attendanceStatusS29 = _vm$attendanceStatusS28.info) === null || _vm$attendanceStatusS29 === void 0 ? void 0 : _vm$attendanceStatusS29.name
    },
    on: {
      "click": function click($event) {
        var _vm$attendanceStatusS30, _vm$attendanceStatusS31, _vm$attendanceStatusS32;
        return _vm.filterByStatus((_vm$attendanceStatusS30 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS30 === void 0 ? void 0 : (_vm$attendanceStatusS31 = _vm$attendanceStatusS30.leave_info) === null || _vm$attendanceStatusS31 === void 0 ? void 0 : (_vm$attendanceStatusS32 = _vm$attendanceStatusS31.info) === null || _vm$attendanceStatusS32 === void 0 ? void 0 : _vm$attendanceStatusS32.id);
      }
    }
  }, [_vm._v(" " + _vm._s((_vm$attendanceStatusS33 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS33 === void 0 ? void 0 : (_vm$attendanceStatusS34 = _vm$attendanceStatusS33.leave_info) === null || _vm$attendanceStatusS34 === void 0 ? void 0 : (_vm$attendanceStatusS35 = _vm$attendanceStatusS34.info) === null || _vm$attendanceStatusS35 === void 0 ? void 0 : _vm$attendanceStatusS35.sort_name) + " " + _vm._s((_vm$attendanceStatusS36 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS36 === void 0 ? void 0 : (_vm$attendanceStatusS37 = _vm$attendanceStatusS36.leave_info) === null || _vm$attendanceStatusS37 === void 0 ? void 0 : _vm$attendanceStatusS37.count) + " ")]), _c('b-badge', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mr-1 cursor-pointer",
    class: _vm.filterWith === ((_vm$attendanceStatusS38 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS38 === void 0 ? void 0 : (_vm$attendanceStatusS39 = _vm$attendanceStatusS38.absent_info) === null || _vm$attendanceStatusS39 === void 0 ? void 0 : (_vm$attendanceStatusS40 = _vm$attendanceStatusS39.info) === null || _vm$attendanceStatusS40 === void 0 ? void 0 : _vm$attendanceStatusS40.id) ? 'badge-glow' : '',
    style: {
      color: 'white',
      backgroundColor: (_vm$attendanceStatusS41 = (_vm$attendanceStatusS42 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS42 === void 0 ? void 0 : (_vm$attendanceStatusS43 = _vm$attendanceStatusS42.absent_info) === null || _vm$attendanceStatusS43 === void 0 ? void 0 : (_vm$attendanceStatusS44 = _vm$attendanceStatusS43.info) === null || _vm$attendanceStatusS44 === void 0 ? void 0 : _vm$attendanceStatusS44.color_code) !== null && _vm$attendanceStatusS41 !== void 0 ? _vm$attendanceStatusS41 : '#7367f0'
    },
    attrs: {
      "title": (_vm$attendanceStatusS45 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS45 === void 0 ? void 0 : (_vm$attendanceStatusS46 = _vm$attendanceStatusS45.absent_info) === null || _vm$attendanceStatusS46 === void 0 ? void 0 : (_vm$attendanceStatusS47 = _vm$attendanceStatusS46.info) === null || _vm$attendanceStatusS47 === void 0 ? void 0 : _vm$attendanceStatusS47.name
    },
    on: {
      "click": function click($event) {
        var _vm$attendanceStatusS48, _vm$attendanceStatusS49, _vm$attendanceStatusS50;
        return _vm.filterByStatus((_vm$attendanceStatusS48 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS48 === void 0 ? void 0 : (_vm$attendanceStatusS49 = _vm$attendanceStatusS48.absent_info) === null || _vm$attendanceStatusS49 === void 0 ? void 0 : (_vm$attendanceStatusS50 = _vm$attendanceStatusS49.info) === null || _vm$attendanceStatusS50 === void 0 ? void 0 : _vm$attendanceStatusS50.id);
      }
    }
  }, [_vm._v(" " + _vm._s((_vm$attendanceStatusS51 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS51 === void 0 ? void 0 : (_vm$attendanceStatusS52 = _vm$attendanceStatusS51.absent_info) === null || _vm$attendanceStatusS52 === void 0 ? void 0 : (_vm$attendanceStatusS53 = _vm$attendanceStatusS52.info) === null || _vm$attendanceStatusS53 === void 0 ? void 0 : _vm$attendanceStatusS53.sort_name) + " " + _vm._s((_vm$attendanceStatusS54 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS54 === void 0 ? void 0 : (_vm$attendanceStatusS55 = _vm$attendanceStatusS54.absent_info) === null || _vm$attendanceStatusS55 === void 0 ? void 0 : _vm$attendanceStatusS55.count) + " ")]), _c('b-badge', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mr-1 cursor-pointer",
    class: _vm.filterWith === ((_vm$attendanceStatusS56 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS56 === void 0 ? void 0 : (_vm$attendanceStatusS57 = _vm$attendanceStatusS56.delay_info) === null || _vm$attendanceStatusS57 === void 0 ? void 0 : (_vm$attendanceStatusS58 = _vm$attendanceStatusS57.info) === null || _vm$attendanceStatusS58 === void 0 ? void 0 : _vm$attendanceStatusS58.id) ? 'badge-glow' : '',
    style: {
      color: 'white',
      backgroundColor: (_vm$attendanceStatusS59 = (_vm$attendanceStatusS60 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS60 === void 0 ? void 0 : (_vm$attendanceStatusS61 = _vm$attendanceStatusS60.delay_info) === null || _vm$attendanceStatusS61 === void 0 ? void 0 : (_vm$attendanceStatusS62 = _vm$attendanceStatusS61.info) === null || _vm$attendanceStatusS62 === void 0 ? void 0 : _vm$attendanceStatusS62.color_code) !== null && _vm$attendanceStatusS59 !== void 0 ? _vm$attendanceStatusS59 : '#7367f0'
    },
    attrs: {
      "title": (_vm$attendanceStatusS63 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS63 === void 0 ? void 0 : (_vm$attendanceStatusS64 = _vm$attendanceStatusS63.delay_info) === null || _vm$attendanceStatusS64 === void 0 ? void 0 : (_vm$attendanceStatusS65 = _vm$attendanceStatusS64.info) === null || _vm$attendanceStatusS65 === void 0 ? void 0 : _vm$attendanceStatusS65.name
    },
    on: {
      "click": function click($event) {
        var _vm$attendanceStatusS66, _vm$attendanceStatusS67, _vm$attendanceStatusS68;
        return _vm.filterByStatus((_vm$attendanceStatusS66 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS66 === void 0 ? void 0 : (_vm$attendanceStatusS67 = _vm$attendanceStatusS66.delay_info) === null || _vm$attendanceStatusS67 === void 0 ? void 0 : (_vm$attendanceStatusS68 = _vm$attendanceStatusS67.info) === null || _vm$attendanceStatusS68 === void 0 ? void 0 : _vm$attendanceStatusS68.id);
      }
    }
  }, [_vm._v(" " + _vm._s((_vm$attendanceStatusS69 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS69 === void 0 ? void 0 : (_vm$attendanceStatusS70 = _vm$attendanceStatusS69.delay_info) === null || _vm$attendanceStatusS70 === void 0 ? void 0 : (_vm$attendanceStatusS71 = _vm$attendanceStatusS70.info) === null || _vm$attendanceStatusS71 === void 0 ? void 0 : _vm$attendanceStatusS71.sort_name) + " " + _vm._s((_vm$attendanceStatusS72 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS72 === void 0 ? void 0 : (_vm$attendanceStatusS73 = _vm$attendanceStatusS72.delay_info) === null || _vm$attendanceStatusS73 === void 0 ? void 0 : _vm$attendanceStatusS73.count) + " ")]), _c('b-badge', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mr-1 cursor-pointer",
    class: _vm.filterWith === ((_vm$attendanceStatusS74 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS74 === void 0 ? void 0 : (_vm$attendanceStatusS75 = _vm$attendanceStatusS74.extreme_delay_info) === null || _vm$attendanceStatusS75 === void 0 ? void 0 : (_vm$attendanceStatusS76 = _vm$attendanceStatusS75.info) === null || _vm$attendanceStatusS76 === void 0 ? void 0 : _vm$attendanceStatusS76.id) ? 'badge-glow' : '',
    style: {
      color: 'white',
      backgroundColor: (_vm$attendanceStatusS77 = (_vm$attendanceStatusS78 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS78 === void 0 ? void 0 : (_vm$attendanceStatusS79 = _vm$attendanceStatusS78.extreme_delay_info) === null || _vm$attendanceStatusS79 === void 0 ? void 0 : (_vm$attendanceStatusS80 = _vm$attendanceStatusS79.info) === null || _vm$attendanceStatusS80 === void 0 ? void 0 : _vm$attendanceStatusS80.color_code) !== null && _vm$attendanceStatusS77 !== void 0 ? _vm$attendanceStatusS77 : '#7367f0'
    },
    attrs: {
      "title": (_vm$attendanceStatusS81 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS81 === void 0 ? void 0 : (_vm$attendanceStatusS82 = _vm$attendanceStatusS81.extreme_delay_info) === null || _vm$attendanceStatusS82 === void 0 ? void 0 : (_vm$attendanceStatusS83 = _vm$attendanceStatusS82.info) === null || _vm$attendanceStatusS83 === void 0 ? void 0 : _vm$attendanceStatusS83.name
    },
    on: {
      "click": function click($event) {
        var _vm$attendanceStatusS84, _vm$attendanceStatusS85, _vm$attendanceStatusS86;
        return _vm.filterByStatus((_vm$attendanceStatusS84 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS84 === void 0 ? void 0 : (_vm$attendanceStatusS85 = _vm$attendanceStatusS84.extreme_delay_info) === null || _vm$attendanceStatusS85 === void 0 ? void 0 : (_vm$attendanceStatusS86 = _vm$attendanceStatusS85.info) === null || _vm$attendanceStatusS86 === void 0 ? void 0 : _vm$attendanceStatusS86.id);
      }
    }
  }, [_vm._v(" " + _vm._s((_vm$attendanceStatusS87 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS87 === void 0 ? void 0 : (_vm$attendanceStatusS88 = _vm$attendanceStatusS87.extreme_delay_info) === null || _vm$attendanceStatusS88 === void 0 ? void 0 : (_vm$attendanceStatusS89 = _vm$attendanceStatusS88.info) === null || _vm$attendanceStatusS89 === void 0 ? void 0 : _vm$attendanceStatusS89.sort_name) + " " + _vm._s((_vm$attendanceStatusS90 = _vm.attendanceStatusSummary) === null || _vm$attendanceStatusS90 === void 0 ? void 0 : (_vm$attendanceStatusS91 = _vm$attendanceStatusS90.extreme_delay_info) === null || _vm$attendanceStatusS91 === void 0 ? void 0 : _vm$attendanceStatusS91.count) + " ")])], 1)] : [_c('div', {
    staticClass: "attendance-spinner-parent"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary"
    }
  })], 1)]], 2), _vm.isLoading ? _c('span', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary"
    }
  })], 1) : _vm.items.length > 0 && !_vm.isLoading ? _c('b-table', {
    attrs: {
      "responsive": "sm",
      "fields": _vm.fields,
      "items": _vm.items,
      "sticky-header": "",
      "height": "300px"
    },
    scopedSlots: _vm._u([{
      key: "cell(status)",
      fn: function fn(data) {
        var _data$item$status$col, _data$item$status, _data$item$status2;
        return [_c('b-badge', {
          style: {
            color: 'white',
            backgroundColor: (_data$item$status$col = (_data$item$status = data.item.status) === null || _data$item$status === void 0 ? void 0 : _data$item$status.color_code) !== null && _data$item$status$col !== void 0 ? _data$item$status$col : '#7367f0'
          },
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(_vm._s((_data$item$status2 = data.item.status) === null || _data$item$status2 === void 0 ? void 0 : _data$item$status2.name))])];
      }
    }, {
      key: "cell(name)",
      fn: function fn(data) {
        var _data$item2;
        return [_c('span', {
          staticClass: "font-weight-bold cursor-pointer text-primary",
          on: {
            "click": function click($event) {
              var _data$item, _data$item$data;
              return _vm.moveToDetails((_data$item = data.item) === null || _data$item === void 0 ? void 0 : (_data$item$data = _data$item.data) === null || _data$item$data === void 0 ? void 0 : _data$item$data.id);
            }
          }
        }, [_vm._v(_vm._s((_data$item2 = data.item) === null || _data$item2 === void 0 ? void 0 : _data$item2.name))])];
      }
    }])
  }) : [_c('div', {
    staticClass: "text-center m-1 mt-3 font-weight-bold"
  }, [_vm._v("No Records Found")])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }