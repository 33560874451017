var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "card-developer-meetup"
  }, [_c('b-card-title', [_vm._v("Upcoming Holidays")]), _vm.items.length > 0 ? _vm._l(_vm.items, function (item, index) {
    return _c('div', {
      key: index
    }, [_c('b-row', {
      staticClass: "mb-2"
    }, [_c('b-col', {
      attrs: {
        "cols": "2"
      }
    }, [_c('h4', {
      staticClass: "mb-0 text-center"
    }, [_vm._v(" " + _vm._s(_vm.formatDate(item.date)) + " ")])]), _c('b-col', {
      attrs: {
        "cols": "8"
      }
    }, [_c('div', {
      staticClass: "my-auto"
    }, [_c('b-card-title', {
      staticClass: "mb-25"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('b-card-text', {
      staticClass: "mb-0"
    }, [_vm._v(" " + _vm._s(item.description) + " ")])], 1)])], 1)], 1);
  }) : [_c('b', {
    staticClass: "d-flex justify-content-center"
  }, [_vm._v("No Upcoming Holiday")])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }