var render = function () {
  var _vm$data, _vm$data$datasets, _vm$data2, _vm$data2$datasets$, _vm$data2$datasets$$d;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "title": "Lead Status"
    }
  }, [((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : (_vm$data$datasets = _vm$data.datasets) === null || _vm$data$datasets === void 0 ? void 0 : _vm$data$datasets.length) > 0 ? [((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : (_vm$data2$datasets$ = _vm$data2.datasets[0]) === null || _vm$data2$datasets$ === void 0 ? void 0 : (_vm$data2$datasets$$d = _vm$data2$datasets$.data) === null || _vm$data2$datasets$$d === void 0 ? void 0 : _vm$data2$datasets$$d.length) > 0 ? [_c('chartjs-component-doughnut-chart', {
    staticClass: "mb-3",
    attrs: {
      "height": 275,
      "data": _vm.data,
      "options": _vm.options
    }
  })] : [_c('p', {
    staticClass: "text-center font-weight-bold"
  }, [_vm._v("No Data Found")])]] : [_c('span', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary"
    }
  })], 1)], _vm._l(_vm.leadStatus, function (lead, index) {
    return _c('div', {
      key: lead.id,
      staticClass: "d-flex justify-content-between",
      class: index < _vm.leadStatus.length - 1 ? 'mb-1' : ''
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('feather-icon', {
      class: lead.color_code,
      attrs: {
        "icon": 'TargetIcon',
        "size": "16"
      }
    }), _c('span', {
      staticClass: "font-weight-bold ml-75 mr-25"
    }, [_vm._v(_vm._s(lead.name))])], 1), _c('div', [_c('b-badge', {
      style: {
        backgroundColor: lead.color_code,
        color: 'white'
      }
    }, [_vm._v(_vm._s(lead.leads_count))])], 1)]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }