var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.assetStatus ? _c('b-card', {
    staticClass: "card-company-table",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('b-card-title', [_vm._v("Assets Summary")]), _vm.assetStatus.length > 0 ? _c('b-table', {
    staticClass: "mb-0",
    attrs: {
      "items": _vm.assetStatus,
      "responsive": "",
      "fields": _vm.fields
    },
    scopedSlots: _vm._u([{
      key: "cell(category)",
      fn: function fn(data) {
        return [_c('b', [_vm._v(_vm._s(data.item.name))])];
      }
    }, {
      key: "cell(assets)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v(" " + _vm._s(data.item.total_assets) + " ")])];
      }
    }, {
      key: "cell(available)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" " + _vm._s(data.item.unassigned_assets_count) + " ")])];
      }
    }, {
      key: "cell(assigned)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v(" " + _vm._s(data.item.assigned_assets_count) + " ")])];
      }
    }, {
      key: "cell(damage)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v(" " + _vm._s(data.item.damaged_assets_count) + " ")])];
      }
    }], null, false, 1305725773)
  }) : [_c('div', {
    staticClass: "text-center m-1 mt-3 font-weight-bold"
  }, [_vm._v("No Records Found")])]], 2)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }