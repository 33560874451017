var render = function () {
  var _vm$data, _vm$data$datasets;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "title": "Lead Contact Status"
    }
  }, [((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : (_vm$data$datasets = _vm$data.datasets) === null || _vm$data$datasets === void 0 ? void 0 : _vm$data$datasets.length) > 0 ? [_vm.enableWidget ? [_c('chartjs-component-doughnut-chart', {
    staticClass: "mb-3",
    attrs: {
      "height": 275,
      "data": _vm.data,
      "options": _vm.options
    }
  })] : _vm._e()] : [_c('span', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary"
    }
  })], 1)], _vm._l(_vm.leadStatus, function (lead, index) {
    return _c('div', {
      staticClass: "d-flex justify-content-between",
      class: index < _vm.leadStatus.length - 1 ? 'mb-1' : ''
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('feather-icon', {
      attrs: {
        "icon": 'TargetIcon',
        "size": "16"
      }
    }), _c('span', {
      staticClass: "font-weight-bold ml-75 mr-25"
    }, [_vm._v(" " + _vm._s(index === 'contacted_count' ? "Contacted" : index === 'not_contacted_count' ? "Not Contacted" : "To be contact") + " ")])], 1), _c('div', {
      staticClass: "mt-1"
    }, [_c('b-badge', {
      style: {
        backgroundColor: index === 'contacted_count' ? _vm.chartColors.successColorShade : index === 'not_contacted_count' ? _vm.chartColors.warningColorShade : _vm.$themeColors.primary,
        color: 'white'
      }
    }, [_vm._v(" " + _vm._s(lead) + " ")])], 1)]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }