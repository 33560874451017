<template>
  <div>
    <!-- Sub Module Count Portion -->

    <b-row>
      <b-col md="12" lg="12" xs="12">
        <DashboardWelcomeWidget />
      </b-col>

      <b-col
        md="12"
        lg="12"
        xs="12"
        v-if="$permissionAbility(SUB_MODULES_COUNT_WIDGET, permissions)"
      >
        <SubModulesCountWidget />
      </b-col>


      <!-- left -->
      <b-col
        md="6"
        lg="6"
        xs="12"
      >
        <template v-if="$permissionAbility(LEAD_STATUS_COUNT_WIDGET, permissions)">
          <LeadStatusCountWidget />
        </template>

        <template v-if="$permissionAbility(ATTENDANCE_SUMMARY_COUNT, permissions)">
          <AttendanceTable />
        </template>

        <template v-if="$permissionAbility(UPCOMING_DUE_MILESTONE_WIDGET, permissions)">
          <UpcomingDueMilestoneWidget />
        </template>

        <template v-if="$permissionAbility(OVER_DUE_MILESTONE_WIDGET, permissions)">
          <OverDueMilestoneWidget />
        </template>

        <template v-if="$permissionAbility(UPCOMING_LEAVE_REQUEST_WIDGET, permissions)">
          <UpcomingLeaveRequestWidget />
        </template>

        <template v-if="$permissionAbility(LESS_WORK_HOUR_WIDGET, permissions)">
          <LessWorkHourWidget />
        </template>

        <!-- <template v-if="$permissionAbility(PENDING_LEAVE_APPLICATION_WIDGET, permissions)">
          <PendingLeaveApplicationWidget />
        </template> -->

        <template v-if="$permissionAbility(LOAN_SUMMARY_WIDGET, permissions)">
          <LoanSummaryWidget />
        </template>

        <template v-if="$permissionAbility(TASK_CHART_WIDGET, permissions)">
          <TaskChartWidget />
        </template>

        <template v-if="$permissionAbility(WORK_HOUR_GRAPH_WIDGET, permissions)">
          <WorkHourGraphWidget />
        </template>

      </b-col>

      <!-- Right -->
      <b-col
        md="6"
        lg="6"
        xs="12"
      >
        <template v-if="$permissionAbility(LEAD_CONTACT_DAY_COUNT_WIDGET, permissions)">
          <LeadContactDayCountWidget />
        </template>

      <template v-if="$permissionAbility(ASSET_SUMMARY_COUNT_WIDGET, permissions)">
        <AssetSummaryCountWidget />
      </template>

      <template v-if="$permissionAbility(PROJECT_SUMMARY_COUNT, permissions)">
        <FinanceProjectSummaryContVue />
      </template>

      <template v-if="$permissionAbility(TASK_SUMMARY_HORIZONTAL_BAR_CHART_WIDGET, permissions)">
        <TaskSummaryHorizontalBarChartWidget />
      </template>

      <template v-if="$permissionAbility(DUE_INVOICE_WIDGET, permissions)">
        <FinanceDueInvoiceWidget />
      </template>

      <template v-if="$permissionAbility(ACCOUNT_PAYABLE_WIDGET, permissions)">
        <FinanceAccountPayableWidget />
      </template>

      <template v-if="$permissionAbility(UPCOMING_AGREEMENT_RENEWAL_WIDGET, permissions)">
        <UpcomingAgreementRenewalWidget />
      </template>

      <template v-if="$permissionAbility(UPCOMING_HOLIDAY_WIDGET, permissions)">
        <UpcomingHolidayWidget />
      </template>

      <template v-if="$permissionAbility(NOTICE_BOARD_WIDGET, permissions)">
        <NoticeBoardWidget />
      </template>



      </b-col>

    </b-row>

    <b-row>
      <b-col cols="12">
        <template v-if="$permissionAbility(INCOME_AND_EXPENSE_WIDGET, permissions)">
          <FinanceIncomeAndExpenseWidget />
        </template>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormSelect,
  BPagination,
  BTable,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BFormGroup,
  BInputGroup,
  BLink,
} from "bootstrap-vue";

import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import LeadStatusCountWidget from "@/layouts/components/dash-board/widgets/LeadStatusCountWidget.vue";
import LeadContactDayCountWidget from "@/layouts/components/dash-board/widgets/LeadContactDayCountWidget.vue";
import AttendanceTable from "@/layouts/components/dash-board/widgets/AttendanceTable.vue";
import AttendanceSummaryCount from "@/layouts/components/dash-board/widgets/AttendanceSummaryCount.vue";
import SubModulesCountWidget from "@/layouts/components/dash-board/widgets/SubModulesCountWidget.vue";
import UpcomingLeaveRequestWidget from "@/layouts/components/dash-board/widgets/UpcomingLeaveRequestWidget.vue";
import UpcomingAgreementRenewalWidget from "@/layouts/components/dash-board/widgets/UpcomingAgreementRenewalWidget.vue";
import AssetSummaryCountWidget from "@/layouts/components/dash-board/widgets/AssetSummaryCountWidget.vue";
import TaskSummaryHorizontalBarChartWidget from "@/layouts/components/dash-board/widgets/TaskSummaryHorizontalBarChartWidget.vue";
import FinanceExpenseChartWidget from "@/layouts/components/dash-board/widgets/FinanceExpenseChartWidget.vue";
import FinanceIncomeChartWidget from "@/layouts/components/dash-board/widgets/FinanceIncomeChartWidget.vue";
import FinanceIncomeAndExpenseWidget from "@/layouts/components/dash-board/widgets/FinanceIncomeAndExpenseWidget.vue";
import FinanceProjectSummaryContVue from "@/layouts/components/dash-board/widgets/FinanceProjectSummaryCont.vue";
import FinanceDueInvoiceWidget from "@/layouts/components/dash-board/widgets/FinanceDueInvoiceWidget.vue";
import FinanceAccountPayableWidget from "@/layouts/components/dash-board/widgets/FinanceAccountPayableWidget.vue";
import UpcomingDueMilestoneWidget from "@/layouts/components/dash-board/widgets/UpcomingDueMilestoneWidget.vue";
import OverDueMilestoneWidget from "@/layouts/components/dash-board/widgets/OverDueMilestoneWidget.vue";
import LessWorkHourWidget from "@/layouts/components/dash-board/widgets/LessWorkHourWidget.vue";
import LeaveBalanceSummaryWidget from "@/layouts/components/dash-board/widgets/LeaveBalanceSummaryWidget.vue";
import PendingLeaveApplicationWidget from "@/layouts/components/dash-board/widgets/PendingLeaveApplicationWidget.vue";
import UpcomingHolidayWidget from "@/layouts/components/dash-board/widgets/UpcomingHolidayWidget.vue";
import NoticeBoardWidget from "@/layouts/components/dash-board/widgets/NoticeBoardWidget.vue";
import LoanSummaryWidget from "@/layouts/components/dash-board/widgets/LoanSummaryWidget.vue";
import TaskChartWidget from "@/layouts/components/dash-board/widgets/TaskChartWidget.vue";
import WorkHourGraphWidget from "@/layouts/components/dash-board/widgets/WorkHourGraphWidget.vue";
import DashboardWelcomeWidget from "@/layouts/components/dash-board/widgets/DashboardWelcomeWidget.vue";
import { mapGetters } from "vuex";

import {
  SUB_MODULES_COUNT_WIDGET,
  LEAD_STATUS_COUNT_WIDGET,
  UPCOMING_LEAVE_REQUEST_WIDGET,
  ATTENDANCE_SUMMARY_COUNT,
  LEAD_CONTACT_DAY_COUNT_WIDGET,
  UPCOMING_AGREEMENT_RENEWAL_WIDGET,
  ASSET_SUMMARY_COUNT_WIDGET,
  TASK_SUMMARY_HORIZONTAL_BAR_CHART_WIDGET,
  EXPENSE_CHART_WIDGET,
  INCOME_CHART_WIDGET,
  INCOME_AND_EXPENSE_WIDGET,
  PROJECT_SUMMARY_COUNT,
  DUE_INVOICE_WIDGET,
  ACCOUNT_PAYABLE_WIDGET,
  UPCOMING_DUE_MILESTONE_WIDGET,
  OVER_DUE_MILESTONE_WIDGET,
  LESS_WORK_HOUR_WIDGET,
  PENDING_LEAVE_APPLICATION_WIDGET,
  UPCOMING_HOLIDAY_WIDGET,
  NOTICE_BOARD_WIDGET,
  LOAN_SUMMARY_WIDGET,
  TASK_CHART_WIDGET,
  WORK_HOUR_GRAPH_WIDGET,
} from "@/helpers/permissionsConstant";
import DashboardWelcomeWidgetVue from "@/layouts/components/dash-board/widgets/DashboardWelcomeWidget.vue";

export default {
  name: "AdminDashboardView",
  components: {
    DashboardWelcomeWidget,
    WorkHourGraphWidget,
    TaskChartWidget,
    LoanSummaryWidget,
    NoticeBoardWidget,
    UpcomingHolidayWidget,
    PendingLeaveApplicationWidget,
    LeaveBalanceSummaryWidget,
    LessWorkHourWidget,
    OverDueMilestoneWidget,
    UpcomingDueMilestoneWidget,
    FinanceAccountPayableWidget,
    FinanceDueInvoiceWidget,
    FinanceProjectSummaryContVue,
    FinanceIncomeAndExpenseWidget,
    FinanceIncomeChartWidget,
    FinanceExpenseChartWidget,
    TaskSummaryHorizontalBarChartWidget,
    AssetSummaryCountWidget,
    UpcomingLeaveRequestWidget,
    UpcomingAgreementRenewalWidget,
    SubModulesCountWidget,
    AttendanceSummaryCount,
    LeadStatusCountWidget,
    LeadContactDayCountWidget,
    AttendanceTable,
    VueGoodTable,
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    StatisticCardVertical,
    BFormSelect,
    BPagination,
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BFormGroup,
    BInputGroup,
    BLink,
  },

  data() {
    return {
      panelNameWithCount: [],
      leadSummaryWithCount: {},

      SUB_MODULES_COUNT_WIDGET,
      LEAD_STATUS_COUNT_WIDGET,
      UPCOMING_LEAVE_REQUEST_WIDGET,
      ATTENDANCE_SUMMARY_COUNT,
      LEAD_CONTACT_DAY_COUNT_WIDGET,
      UPCOMING_AGREEMENT_RENEWAL_WIDGET,
      ASSET_SUMMARY_COUNT_WIDGET,
      TASK_SUMMARY_HORIZONTAL_BAR_CHART_WIDGET,
      EXPENSE_CHART_WIDGET,
      INCOME_CHART_WIDGET,
      INCOME_AND_EXPENSE_WIDGET,
      PROJECT_SUMMARY_COUNT,
      DUE_INVOICE_WIDGET,
      ACCOUNT_PAYABLE_WIDGET,
      UPCOMING_DUE_MILESTONE_WIDGET,
      OVER_DUE_MILESTONE_WIDGET,
      LESS_WORK_HOUR_WIDGET,
      PENDING_LEAVE_APPLICATION_WIDGET,
      UPCOMING_HOLIDAY_WIDGET,
      NOTICE_BOARD_WIDGET,
      LOAN_SUMMARY_WIDGET,
      TASK_CHART_WIDGET,
      WORK_HOUR_GRAPH_WIDGET,
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      const [panelNameWithCount, leadSummaryWithCount] = await Promise.all([
        this.getPanelCount({
          select: "divisions,departments,designations,users,roles",
        }),
        this.getLeadSummaryCount(),
      ]);

      this.panelNameWithCount = panelNameWithCount?.data?.data;

      this.leadSummaryWithCount = leadSummaryWithCount?.data;
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },
  methods: {
    async getPanelCount(params) {
      return await this.$api.get("api/dashboard/admin-dashboard/panel-count", {
        params: {
          select: params.select,
        },
      });
    },

    async getLeadSummaryCount() {
      return await this.$api.get(
        "api/dashboard/admin-dashboard/lead-summary-count"
      );
    },
  },
};
</script>

<style lang="scss">
.custom-card-statistics .statistics-body {
  padding: 0rem 2.4rem 1rem !important;
}
// .table-custom-style {
//   font-size: 13px !important;
//   white-space: nowrap !important;
//   min-height: 140px !important;
//   tr,
//   th,
//   td {
//     vertical-align: middle !important;
//     text-align: center !important;
//   }
// }

// .table-cell-margin {
//   margin: 8px 0px;
// }
.lead-table {
  padding: 0px !important;
}
</style>
