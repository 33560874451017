<template>
  <b-card v-if="notOverdueData.length && overdueData.length">
    <label class="custom-font"><b>Task Summary</b></label>
    <br />

    <b-col cols="6">
      <v-select
        v-model="filterDayRange"
        :options="dayRangeOptions"
        v-on:input="loadSelectedDayRange"
        :reduce="(item) => item.id"
        label="name"
        placeholder="Filter by day range"
        class="mr-sm-1 mb-1 mb-sm-0 custom-font"
      />
    </b-col>

    <br />

    <div v-if="loadTaskSummaryChart">
      <app-echart-bar :option-data="chartOption" />
    </div>

    <div v-else>
      <template>
        <div class="text-center">
          <b-spinner variant="primary" label="Text Centered" />
        </div>
      </template>
    </div>

  </b-card>
</template>


<script>
import { BCard, BCol, BSpinner } from "bootstrap-vue";
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "TaskSummaryHorizontalBarChartWidget",
  components: {
    BCard,
    AppEchartBar,
    BCol,
    BSpinner,
  },
  data() {
    return {
      notOverdueData: [],
      overdueData: [],

      loadTaskSummaryChart: true,

      // Filter
      filterDayRange: "",
      dayRangeOptions: [
        { id: "today", name: "Today" },
        { id: "week", name: "This Week" },
        { id: "month", name: "This Month" },
        { id: "year", name: "This Year" },
        { id: "all", name: "All" },
      ],
    };
  },
  computed: {
    chartOption() {
      return {
        xAxis: [
          {
            type: "value",
          },
        ],
        yAxis: [
          {
            type: "category",
            data: ["Completed", "On Going", "Not Started"],
            splitLine: { show: true },
          },
        ],
        grid: {
          left: "100px",
          right: "30px",
          bottom: "30px",
        },
        series: [
          {
            name: "Not Overdue",
            type: "bar",
            stack: "advertising",
            data: [
              this.notOverdueData[0],
              this.notOverdueData[1],
              this.notOverdueData[2],
            ],
            barWidth: "30%",
            itemStyle: {
              color: '#28c76f'
            }
          },
          {
            name: "Overdue",
            type: "bar",
            stack: "advertising",
            data: [
              this.overdueData[0],
              this.overdueData[1],
              this.overdueData[2],
            ],
            barWidth: "30%",
            itemStyle: {
              barBorderRadius: [0, 10, 10, 0],
              color: '#ea5455'
            },
          },
        ],
      };
    },
  },

  async created() {
    try {
      await this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    async getTaskSummaryCount() {
      return await this.$api.get("api/dashboard/task-summary", {
        params: {
          day_range: this.filterDayRange,
        },
      });
    },

    async loadItems() {
      this.loadTaskSummaryChart = false;
      try {
        const taskSummaryCount = await this.getTaskSummaryCount();

        this.notOverdueData = [
          taskSummaryCount.data.completed_not_overdue,
          taskSummaryCount.data.ongoing_not_overdue,
          taskSummaryCount.data.not_started_not_overdue,
        ];

        this.overdueData = [
          taskSummaryCount.data.completed_overdue,
          taskSummaryCount.data.ongoing_overdue,
          taskSummaryCount.data.not_started_overdue,
        ];

        this.loadTaskSummaryChart = true;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    loadSelectedDayRange() {
      console.log(this.filterDayRange);

      if (this.filterDayRange) {
        this.loadItems();
      } else {
        this.filterDayRange = "all";
        this.loadItems();
      }
    },
  },
};
</script>

