<template>
    <b-card
      style="margin-bottom: 10px !important"
    >
      <b-card-header>
        <label class="custom-font"><b>Project Status Summary</b></label>
      </b-card-header>

      <template v-if="data?.datasets">
        <template v-if="data?.datasets[0]?.data?.length > 0">
          <chartjs-component-doughnut-chart
              :height="275"
              :data="data"
              :options="options"
              class="mb-3"
          />
        </template>
        <template v-else>
          <b class="d-flex justify-content-center mb-1">No Data Found</b>
        </template>
      </template>
      <template v-else>
      <span class="d-flex justify-content-center align-items-center">
        <b-spinner variant="primary"/>
      </span>
      </template>

      <div class="d-flex justify-content-between mb-1">
        <div class="d-flex align-items-center">
          <span class="font-weight-bold ml-75 mr-25">Total</span>
        </div>
        <div class="mt-1">
          <b-badge :style="{ backgroundColor: chartColors.primaryColorShade }">
            {{ projectStatusCount.total_projects }}
          </b-badge>
        </div>
      </div>

      <div class="d-flex justify-content-between mb-1">
        <div class="d-flex align-items-center">
          <span class="font-weight-bold ml-75 mr-25">Approved</span>
        </div>
        <div class="mt-1">
          <b-badge :style="{ backgroundColor: chartColors.blueLightColor }">
            {{ projectStatusCount.all_approved_projects }}
          </b-badge>
        </div>
      </div>

      <div class="d-flex justify-content-between mb-1">
        <div class="d-flex align-items-center">
          <span class="font-weight-bold ml-75 mr-25">Pending</span>
        </div>
        <div class="mt-1">
          <b-badge :style="{ backgroundColor: chartColors.warningColorShade }">
            {{ projectStatusCount.all_pending_projects }}
          </b-badge>
        </div>
      </div>


      <div class="d-flex justify-content-between mb-1">
        <div class="d-flex align-items-center">
          <span class="font-weight-bold ml-75 mr-25">Not Started</span>
        </div>
        <div class="mt-1">
          <b-badge :style="{ backgroundColor: chartColors.warningLightColor }">
            {{ projectStatusCount.not_started_projects }}
          </b-badge>
        </div>
      </div>

      <div class="d-flex justify-content-between mb-1">
        <div class="d-flex align-items-center">
          <span class="font-weight-bold ml-75 mr-25">Ongoing</span>
        </div>
        <div class="mt-1">
          <b-badge :style="{ backgroundColor: chartColors.infoColorShade }">
            {{ projectStatusCount.ongoing_projects }}
          </b-badge>
        </div>
      </div>

      <div class="d-flex justify-content-between mb-1">
        <div class="d-flex align-items-center">
          <span class="font-weight-bold ml-75 mr-25">Finished</span>
        </div>
        <div class="mt-1">
          <b-badge :style="{ backgroundColor: chartColors.successColorShade }">
            {{ projectStatusCount.finished_projects }}
          </b-badge>
        </div>
      </div>
    </b-card>
  </template>

    <script>
  import {
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormSelect,
    BPagination,
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BFormGroup,
    BInputGroup,
    BLink,
    BSpinner,
  } from "bootstrap-vue";

  import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import ChartjsComponentPolarAreaChart
    from "@/layouts/components/dash-board/widgets/charts-components/ChartjsComponentPolarAreaChart.vue";
  import {$themeColors} from "@themeConfig";
  import ChartjsComponentDoughnutChart
    from "@/layouts/components/dash-board/widgets/charts-components/ChartjsComponentDoughnutChart.vue";

  export default {
    name: "ProjectSummaryCount",
    computed: {
      $themeColors() {
        return $themeColors
      }
    },
    components: {
      ChartjsComponentDoughnutChart,
      ChartjsComponentPolarAreaChart,
      BSpinner,
      BCard,
      BRow,
      BCol,
      BCardHeader,
      BCardTitle,
      BCardText,
      BCardBody,
      BMedia,
      BMediaAside,
      BMediaBody,
      StatisticCardVertical,
      BFormSelect,
      BPagination,
      BTable,
      BFormCheckbox,
      BAvatar,
      BBadge,
      BFormGroup,
      BInputGroup,
      BLink,
    },

    data() {
      return {
        enableWidget: false,
        projectStatusCount: {
          total_projects: 0,
          all_approved_projects: 0,
          all_pending_projects: 0,
          not_started_projects: 0,
          ongoing_projects: 0,
          finished_projects: 0,
        },
        data: {},
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          cutoutPercentage: 60,
          legend: { display: false },
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                const label = data.datasets[0].labels[tooltipItem.index] || ''
                const value = data.datasets[0].data[tooltipItem.index]
                const output = ` ${label} : ${value}`
                return output
              },
            },
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: '',
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
        },
        chartColors: {
          primaryColorShade: '#836AF9',
          yellowColor: '#ffe800',
          successColorShade: '#28dac6',
          warningColorShade: '#ffe802',
          warningLightColor: '#FDAC34',
          infoColorShade: '#299AFF',
          greyColor: '#4F5D70',
          blueColor: '#2c9aff',
          blueLightColor: '#84D0FF',
          greyLightColor: '#EDF1F4',
          tooltipShadow: 'rgba(0, 0, 0, 0.25)',
          lineChartPrimary: '#666ee8',
          lineChartDanger: '#ff4961',
          labelColor: '#6e6b7b',
          grid_line_color: 'rgba(200, 200, 200, 0.2)',
        },
        projectStatusCount: []
      }
    },
    async mounted() {
      const [projectStatusData] = await Promise.all([this.getProjectSummaryCount()]);
      const projectStatusCount = projectStatusData.data?.data
      this.projectStatusCount = projectStatusCount

      let countData = [
        projectStatusCount?.total_projects,
        projectStatusCount?.all_approved_projects,
        projectStatusCount?.all_pending_projects,
        projectStatusCount?.not_started_projects,
        projectStatusCount?.ongoing_projects,
        projectStatusCount?.finished_projects
      ]

      this.data = {
        datasets: [
          {
            labels: ['Total', 'Approved', 'Pending', 'Not Started', 'Ongoing', 'Finished'],
            data: countData,
            backgroundColor: [
              this.chartColors.primaryColorShade,
              this.chartColors.blueLightColor,
              this.chartColors.warningColorShade,
              this.chartColors.warningLightColor,
              this.chartColors.infoColorShade,
              this.chartColors.successColorShade,
            ],
            borderWidth: 0,
            pointStyle: 'rectRounded',
          },
        ],
      }

      this.options.tooltips.shadowColor = this.chartColors.tooltipShadow
    },
    methods: {
      async getProjectSummaryCount() {
        return await this.$api.get(
          "api/dashboard/project-status-summary"
        );
      },
    },
  };
  </script>

    <style lang="scss">
  .custom-card-statistics .statistics-body {
    padding: 0rem 2.4rem 1rem !important;
  }

  .lead-table {
    padding: 0px !important;
  }
  </style>
